.profile{
    position:relative;
    padding-top: 3.5rem;
}

.profile-container{
    row-gap:1rem;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
}

.profile-data{
    display: grid;
    justify-content: right;
    width: 40%;
    margin: 0 2rem;
}
.profile-information{
    display: grid;
    justify-content: left;
    width: 60%;
    margin: 0 2rem;
}
.profile-prefil{
    width: 100px;
    height: 100px;

    background: linear-gradient(180deg, var(--first-color) 0%, #eee 100%);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.profile-prefil img{
    width: 100px;
    height: auto;
}

.profile-border{
    border: 4px solid var(--first-color);
    justify-self: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-bottom: 0.75rem;
}

.profile-name{
    font-size: var(--h2-font-size);
}

.profile-profession{
    font-size: var(--smaller-font-size);
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.profile-social{
    display: flex;
    justify-content: center;
    column-gap: 0.75rem;
}

.profile-social-link{
    font-size: 1.5rem;
    margin: 0 0.5rem;
    color: var(--title-color);
    transition: 0.2s ease;
}

.profile-social-link:hover{
    color: var(--first-color);
}


.profile-info{

column-gap: 2.5rem;
}

.profile-info-group{
    text-align: center;
}
.profile-info-about{
    width: 60%;
    font-size: var(--normal-font-size);
    text-align: justify;
    /* justify-content: center; */
    /* margin: 0 auto; */
}
.profile-info-num{
    font-size: var(--normal-font-size);
    margin-bottom: 0.25rem;
}

.profile-info-des{
    font-size: var(--smaller-font-size);
}


.profile-buttons, .profile-buttons-small{
    font-weight:500;
display: flex;
align-items: center;
/* justify-content: center; */
}

.profile-buttons{
    column-gap:1rem ;
    margin-top: 1rem;
}
.text{
    position: relative;
}
.multiple-text:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: red;
    border-left: 2px solid var(--first-color);
    animation: animate 3s steps(10) infinite;
}
/* ================= Buttons =================*/

.button{
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: var(--first-color);
    color: white;
    padding: 1.2rem 1.5rem;
    border-radius: 0.5rem;
    transition: 0.3s;
    margin: 0 0.5rem;
    /* box-shadow: 0 8px 24px hsla(var(--hue), var(--sat), var(--lig), 0.2) ; */
}

.button i{
    font-size: 1.25rem;
}

.button:hover{
    background-color: var(--first-color-alt);
}

.buttons-small{
    padding: 0.75rem;
    box-shadow: none;
}

.button-gray{
    background-color: var(--container-color);
    color: var(--title-color);
}

.button-gray:hover{
    background-color: var(--text-color-lighten);
}



/* ============ Theme button ========= */

.change-theme{
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
    transition: 0.3s ease;
}

.change-theme:hover{
    color: var(--first-color);
}


body.dark-theme{
    --title-color: #fff;
    --text-color: #d3d3d3;
    --text-color-light: #d3d3d39c;
  --text-color-lighten: #d3d3d34c;
    --body-color:#232931;
    --container-color: #393E46;
}

/* .dark-theme .button-gray{
    background-color: var(--container-color);
} */

/* .dark-theme .button-gray:hover{
    background-color: var(--body-color);
} */

@media (max-width: 1024px) {
    .profile-info-about{
        width: 70%;
    }
}

@media (max-width:768px) {
    .profile-info-about{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .profile-container{
        flex-direction: column;
        justify-content: center;
    }
    .profile-data{
        justify-content: center;
    }
    .profile-buttons{
        justify-content: center;
    }
    .profile-info{
        justify-content: center;
    }

    .profile-information{
       width: 100%;
       justify-content: center;
        margin: 0 auto;
    }
    

}
@media (max-width: 450px) {
    .profile-buttons{
        flex-direction: column;
    }
    .profile-buttons-small{
        margin-top: 0.5rem;
    }
    .profile-info-about{
        width: 90%;
    }
}

@keyframes animate{
    40%, 60%{
        left: calc(100% + 2px);
    }
    100%{
        left: 0%;
    }
}