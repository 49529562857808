/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --first-color: #5C469C;
  --first-color-alt: #6b51b6;
  --title-color: #232931;
  --text-color: rgb(92, 92, 92);
  --text-color-light: #393e46ab;
  --text-color-lighten: #393e4632;
  --body-color: #eeeeee;
  --container-color: #fff;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Poppins', sans-serif;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;
}

@media screen and (min-width: 968px) {
  :root {
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: .3s; /* For animation dark mode */
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: 600;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.grid{
  display: grid;
}


.App {
  text-align: center;
}

@media (max-width: 1024px) {
  html{
    font-size: 95%;
  }
}

@media (max-width: 768px) {
  html{
    font-size: 90%;
  }
}

@media (max-width: 450px) {
  html{
    font-size: 85%;
  }
}
