.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

/* ========= Filter Buttons ========= */
.filters-content{
 margin: 2.5rem auto;
 background-color: var(--text-color-lighten);
 padding: 0.375rem;
 border-radius: 0.75rem;  
 display: flex;
 justify-self: center; 
 column-gap: 0.5rem;
 width: 60%;
}
.filters-sections{
    margin: 0 3rem;
}
.filters-btn{
    width: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    color: var(--title-color);
    font-size: var(--small-font-size);
    font-family: var(--body-font);
    font-weight: 500;
    border-radius: 0.75rem;
    cursor: pointer;
    background-color: transparent;
    transition: 0.3s;
}

.filters-btn:hover{
    background-color: var(--container-color);
}

.filter-btn-active{
    background-color: var(--container-color);
}


  .portfolio-content{
    padding: 0 2rem;
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    /* grid-template-columns: 1fr; */
}
.portfolio-data{
    /* color: var(--first-color); */
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}
.portfolio-img{
    height: auto;
    width: 18rem !important;
    margin:  3rem auto;
     border-radius: 0.5rem;
    justify-content: center;
    /* text-align: center; */
}
.portfolio-title{
    font-size: 1.5rem;
    margin: 0.5rem;
    font-size: var(--h3-font-size);
}
.portfolio-description{
    margin: 0.25rem;
    padding: 0 0.5rem;
    font-size: var(--normal-font-size);
    text-align: justify;
}
.btn-icon{
    display: inline-block;
    transition: all 0.3s;
}
.portfolio-btn{
    background-color: var(--first-color);
    color: var(--fourth-color);
    padding: 0.8rem 1rem;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-bottom: 2.25rem;
}
.portfolio-btn:hover i{
    transform: translateX(5px) !important;
}

.swiper-button-next, .swiper-button-prev{
    color: var(--first-color) !important;
    margin: 0 3rem;
}

.swiper-pagination-bullet-active{
    background-color:var(--first-color) !important; ;
}




/*================ Skills ================ */
.skills-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}
.skill-content{
    margin: 1rem;
    text-align: center;
    height: 16rem;
    width: 18rem;
    border-radius: 0.5rem;
    /* border: 1px solid var(--first-color-alt); */
    box-shadow: 0rem 0.25rem 0.25rem 0rem var(--first-color-alt);
    background-color: var(--container-color);
}
.skill-icon{
    color: var(--third-color);
}
.skill-body{
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    
}
.skill-column-1,.skill-column-2{
    text-align: left;
}
.skill-column-1{
    margin-right: 1rem;
}
.skill-column-2{
    margin-left: 1rem;
}
.skill-title{
    text-align: center;
    color: var(--title-color);
    font-size: var(--h3-font-size);
    margin-top: 1.5rem ;
}
.skill{
    margin: 1.2rem 0.8rem;
}
.skill-name{
    font-size: var(--normal-font-size);
    color: var(--title-color);
    font-weight: 500;
    line-height: 1rem;
}
.skill-experience{
    color: var(--text-color);
    font-size: var(--smaller-font-size);
}


.mySwiper{
    padding: 0 3rem;
    /* background-color: var(--body-color); */
}

.projects-content{
    background-color: var(--body-color);
    display: none;
}
.skills-content{
    display: none;
}

.filter-active{
    display: grid;
}


@media (max-width: 1024px) {
    .filters-content{
        width: 70%;
    }
}

@media (max-width: 768px) {
    .filters-content{
        width: 80%;
    }
    .portfolio-content{
        flex-direction: column;
        padding: 0 1.5rem;
        width: 90%;
        margin: 0 auto;
    }
    .portfolio-img{
        margin: 1rem auto;
    }
    .swiper-button-next, .swiper-button-prev{
        margin: 0 1.5rem;
    }
    .swiper-button-next{
        right: var(--swiper-navigation-sides-offset, -8px) !important;
    }
    
    .swiper-button-prev{
        left: var(--swiper-navigation-sides-offset,-8px) !important;
    }
}

@media (max-width: 450px) {
    .filters-content{
        width: 90%;
    }
    .portfolio-img{
        width: 15rem !important;
    }
    .skill-content{
        height: 15rem;
        width: 17rem;
    }
    .portfolio-content{
        padding: 0 1rem;
    }
    .mySwiper{
        width: 100%;
    }
    .swiper{
        width: 100% !important;
    }
    .swiper-button-next, .swiper-button-prev{
        
        margin: 0 0.5rem;
        font-size: 0.5rem !important;
    }
}